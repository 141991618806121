/*
 * Servizio che si occupa della gestione dei gruppi
*/

module GroupService {
    let app = angular.module("app");

    app.factory("GroupService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {    
            // Crea un nuovo gruppo
            createGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/create-group', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Cancella il gruppo selezionato
            deleteGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/delete-group/:groupId', {
                groupId: "@groupId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera le informazioni di un gruppo
            getGroupDetail: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-group-by-id/:groupId/:withUsersData', {
                groupId: "@groupId",
                withUsersData: "@withUsersData"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Ricerca dei gruppi in infinite scroll con filtri
            searchGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/search-groups/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            countTotalGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-groups', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            getGroupReportUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/items-status-for-group/:groupId', {
                groupId: "@groupId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiunge un utente al gruppo
            addUserToGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/add-user-to-group', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiunge una lista di utenti al gruppo
            addUsersToGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/add-users-to-group', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Rimuove una lista di utenti al gruppo
            removeUsersFromGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/remove-users-from-group', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiorna il gruppo
            updateGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/update-group', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}